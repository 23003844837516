import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";
import { colors } from "./constants";
import "antd/dist/antd.css";

const GlobalStyles = createGlobalStyle`
  

  
h1.lexra{
  font-size: 16px ;
  margin-bottom: 0px;
}

.item-type-block{
  padding:2px;
    text-align:center;
    padding-left:15px;
    padding-right:15px;
    font-size:12px;
    min-height:27px;
}
header .item-type-block{
  padding-top:5px;
}
  .Gold{
    background: #D4AF37;
    background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D4AF37), color-stop(100%, #C5A028));
    background: -webkit-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
    background: -moz-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
    background: -o-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
    background: -ms-linear-gradient(top, #D4AF37 0%, #C5A028 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D4AF37', endColorstr='#C5A028', GradientType=0);
    border: 1px solid #B69119;
  }
  .Silver{
    background: #C0C0C0;
    background: linear-gradient(to bottom, #C0C0C0 0%, #B1B1B1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #C0C0C0), color-stop(100%, #B1B1B1));
    background: -webkit-linear-gradient(top, #C0C0C0 0%, #B1B1B1 100%);
    background: -moz-linear-gradient(top, #C0C0C0 0%, #B1B1B1 100%);
    background: -o-linear-gradient(top, #C0C0C0 0%, #B1B1B1 100%);
    background: -ms-linear-gradient(top, #C0C0C0 0%, #B1B1B1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C0C0C0', endColorstr='#B1B1B1', GradientType=0);
    border: 1px solid #A2A2A2;
  }
  .Platinum, .Palladium{
    color:#666666;
    background: #E5E4E2;
    background: linear-gradient(to bottom, #E5E4E2 0%, #D6D5D3 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E5E4E2), color-stop(100%, #D6D5D3));
    background: -webkit-linear-gradient(top, #E5E4E2 0%, #D6D5D3 100%);
    background: -moz-linear-gradient(top, #E5E4E2 0%, #D6D5D3 100%);
    background: -o-linear-gradient(top, #E5E4E2 0%, #D6D5D3 100%);
    background: -ms-linear-gradient(top, #E5E4E2 0%, #D6D5D3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E5E4E2', endColorstr='#D6D5D3', GradientType=0);
    border: 1px solid #C7C6C4;
    box-shadow: inset 0 1px 0 #F4F3F1;
    -webkit-box-shadow: inset 0 1px 0 #F4F3F1;
    -moz-box-shadow: inset 0 1px 0 #F4F3F1;
  }

  .ant-btn-primary{
    background:${palette("primary", 0)} !important;
    border-color:${palette("primary", 0)} !important;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
    margin-right:0px !important;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table table{
    table-layout: auto !important;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    padding: 6px 6px !important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font("primary", 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    "" /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.horizontal-form{
  display: flex;
}
.isoEditIcon{
  font-size: 1rem;
  svg{
    margin-left: .5rem;
    color: ${palette("primary", 0)};
  }
}
.isoEditDataWrapper{
  width: 60%;
  svg{
    margin-left: .5rem;
    color: #52c41a;
  }
}
.item-search-selected{
  background-color: #006600;

}
.item-search-selected > .ant-select-selector{
  background-color: #006600 !important;
  color: white;

}

.custom-dropdown{
  width: 300px; 
}

.report-class .ant-table-thead{
  font-size: 11px;
  font-weight: bold;
 border: 1px solid #8a8a8a; 
}
.report-class .ant-table-cell{
  font-size: 11px;
  border: 1px solid #8a8a8a; 
  border-color: #8a8a8a;  }

.report-class .ant-table-container table > thead > tr:first-child th{
  border-color: #8a8a8a; 
}
.ant-table-tbody > tr > td a{
  color: #6d6d6d !important;
}
.searchAdv .ant-form-item-label > label{
  font-size: 11px !important;
  margin-left:5px;
  align-items: center;
  align-text: left;
}
.searchAdv .ant-form-item-label{
  text-align: left;
}
 .searchAdv .ant-input,  .searchAdv .ant-picker {
  height: 28px !important;
  font-size: 11px !important;
}
.searchAdv .ant-select-selector{
  font-size: 11px !important;
  padding: 0 8px;
  height: 28px !important;

}

.searchAdv .ant-picker-input > input, .searchAdv .ant-select-selection-placeholder{
  font-size: 11px;
  line-height: 26px !important;
}
.ant-select-item{

  font-size: 12px !important;

}

.ant-form-item{
    margin-bottom: 0 !important;
}
.InvoiceTableBtn{
  margin-top: 14px;
}

.searchAdv .ant-row.ant-form-item {
  padding: 0 3px ;
  display: flex;
  flex-direction: column;
  width: 100% !important;

}
.searchAdv .ant-form-item-control-input-content{
  width: 170px
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #c6c6c6 !important;
}
.searchAdv .ant-select-single .ant-select-selector .ant-select-selection-item{
  line-height: 26px;
}
 .col-btn{
  display: flex;
  align-items: end;

}
.numberTotal .ant-input-number-input{
  text-align: right !important;
}
.ant-table-tbody > tr > td {
  border: 1px solid;
}
.searchAdv .col-btn .ant-form-item-control-input-content{
  width: auto !important;
}
.ant-table-tbody > tr > td button a{
color: #fff !important;
}
.grading{
  background: #00ff4c !important;
}
.grading td{
  background: #00ff4c !important;
}
.memo{
  background: #ffe8b3 !important;
 
}
.memo td{
  background: #ffe8b3 !important;

}

.ant-table-tbody > .memo:hover:not(.ant-table-expanded-row) > td{
  background: #f9cc64 !important;
}
.ant-table-tbody > .grading:hover:not(.ant-table-expanded-row) > td{
  background: #71ff9c !important;
}
.ant-radio-inner{
  border: 1px solid #000 !important;
}

.ant-tabs-tab{
  font-weight: bold;
}

.ant-tabs-tab-btn {
    font-size: 16px;
}

th.ant-table-cell{
  text-transform: uppercase;
  font-size: 14px;
}

.loading-table .ant-table-placeholder {
  display: none;
}

td.ant-table-cell,.ant-table-tbody > tr > td > a, .eYsXYk .ant-table-tbody > tr > td
{
  font-size: 12px !important;
}

table.ant-table {
  table-layout: fixed !important;
}


.input-description.PURCHASE,.input-description.MEMO_IN{
  min-width: 200px !important;
}
.itemCategory.PURCHASE,.itemCategory.MEMO_IN{
  width: 100% !important;
}
.itemService.PURCHASE,.itemService.MEMO_IN{
  width: 150px !important;
}
.item-location.PURCHASE,.item-location.MEMO_IN{
  min-width: 120px !important;
}
.item-owner.PURCHASE, .item-owner.MEMO_IN{
  min-width: 70px !important;
}

`;


export default GlobalStyles;
